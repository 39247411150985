
import { GetSchoolClassDto } from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';

@Component({
  components: {},
  mixins: [validationMixin],
  validations: {
    name: { required },
  },
})
export default class ManageClasses extends Vue {
  clientModule: ClientModule = getModule(ClientModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop()
  show!: boolean;

  @Prop()
  schoolId!: string;

  @Prop()
  schoolClass?: GetSchoolClassDto;

  name: string | null = null;

  loading = false;

  @Watch('schoolClass', { immediate: true })
  onSchoolClassChange() {
    if (this.schoolClass) {
      this.name = this.schoolClass.name;
    } else {
      this.name = null;
    }
  }

  get isEdit(): boolean {
    return this.schoolClass !== null;
  }

  async addSchoolClass() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        this.loading = true;
        await this.clientModule.addSchoolClass({
          schoolId: this.schoolId,
          name: this.name!,
        });
        this.edited();
        this.snackbarModule.showMessage({
          message: this.$i18n.t('manageClass.add.success').toString(),
          isSuccess: true,
        });
        this.close();
      } catch (error: any) {
        if (error?.response?.status === 409) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('manageClass.edit.conflictError').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('manageClass.add.error').toString(),
            isSuccess: false,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }

  async editSchoolClass() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        this.loading = true;
        await this.clientModule.renameSchoolClass({
          schoolId: this.schoolId,
          name: this.schoolClass!.name,
          newName: this.name!,
        });
        this.edited();
        this.snackbarModule.showMessage({
          message: this.$i18n.t('manageClass.edit.success').toString(),
          isSuccess: true,
        });
        this.close();
      } catch (error: any) {
        if (error?.response?.status === 409) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('manageClass.edit.conflictError').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('manageClass.edit.error').toString(),
            isSuccess: false,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }

  get nameErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('manageClass.add.nameError').toString();
    if (!this.$v.name.$dirty) return errors;
    !this.$v.name.required && errors.push(requiredError);
    return errors;
  }

  @Emit()
  edited() {
    return true;
  }

  @Emit()
  close() {
    this.$v.$reset();
    this.name = null;
    return true;
  }
}
