
import { GetSchoolClassDto } from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AddEditClass from './AddEditClass.vue';

@Component({
  components: { AddEditClass },
})
export default class ManageClasses extends Vue {
  clientModule: ClientModule = getModule(ClientModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop()
  schoolId!: string;

  schoolClasses: GetSchoolClassDto[] = [];
  chosenClass: GetSchoolClassDto | null = null;

  showAddEditDialog = false;
  showDeleteDialog = false;
  loadingDelete = false;

  async fetchClasses() {
    try {
      this.schoolClasses = await this.clientModule.getCurrentClasses(
        this.schoolId,
      );
    } catch (e) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageClass.fetch.error').toString(),
        isSuccess: false,
      });
    }
  }

  async mounted() {
    await this.fetchClasses();
  }

  async deleteClass(classToDelete?: GetSchoolClassDto | null) {
    if (!classToDelete) return;
    try {
      this.loadingDelete = true;
      await this.clientModule.deleteAllStudentsOfClass({
        schoolId: this.schoolId,
        grade: classToDelete.name,
      });
      this.onUpdate();
      this.cancel();
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageClass.delete.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      console.log(error);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('manageClass.delete.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingDelete = false;
    }
  }

  openAddEditDialog(schoolClass?: GetSchoolClassDto) {
    this.showAddEditDialog = true;
    // INFO prevent pointer event from being schoolclass
    if (schoolClass?.name) {
      this.chosenClass = schoolClass;
    }
  }

  openDeleteDialog(classToDelete: GetSchoolClassDto) {
    if (classToDelete?.name) {
      this.chosenClass = classToDelete;
      this.showDeleteDialog = true;
    }
  }

  cancel() {
    this.showAddEditDialog = false;
    this.showDeleteDialog = false;
    this.chosenClass = null;
  }

  @Emit()
  async onUpdate() {
    await this.fetchClasses();
  }
}
