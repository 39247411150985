
import ImportToMongoButton from './ImportToMongoButton.vue'
import { Client, GetClientDto } from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import dayjs from 'dayjs';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import AddGroup from './AddGroup.vue';
import EditGroup from './EditGroup.vue';
import MissingProperty from './MissingProperty.vue';

@Component({ components: { AddGroup, EditGroup, MissingProperty, ImportToMongoButton } })
export default class GroupTable extends Vue {
  clientModule: ClientModule = getModule(ClientModule, this.$store);
  showCreateClient = false;
  search = '';
  headers = [
    { text: 'ID', value: 'schoolId', align: 'center' },
    { text: 'Name', value: 'schoolName' },
    { text: 'slug', value: 'slug' },
    { text: 'Lizenz bis', value: 'licenseUntil' },
    // { text: 'Klassen', value: 'classes' },
    { text: 'Offene Tests', value: 'validTests' },
    {
      text: 'Typ',
      value: 'clientType',
    },
    { text: 'Vorname', value: 'adminFirstname' },
    { text: 'Nachname', value: 'adminLastname' },
    { text: 'Email', value: 'adminEmail' },
    { text: 'Straße', value: 'street' },
    { text: 'PLZ', value: 'postcode' },
    { text: 'Ort', value: 'city' },
    { text: 'Land', value: 'country' },
    { text: 'URL', value: 'url' },
  ];
  chosenId: string | null = null;

  get clients(): GetClientDto[] {
    return this.clientModule.allClients;
  }

  get isTablePage(): boolean {
    return this.$route.name === 'groups';
  }

  get isCreatePage(): boolean {
    return this.$route.name === 'groups-create';
  }

  get isDetailPage(): boolean {
    return this.$route.name === 'groups-detail';
  }

  hasValidTestsProperty(item: GetClientDto): boolean {
    return !!item.validTests || item.validTests === 0;
  }

  setRoute(route: 'all' | 'detail' | 'create') {
    switch (route) {
      case 'all':
        this.chosenId = null;
        this.$router.push({ name: 'groups' });
        break;
      case 'detail':
        this.$router.push({
          name: 'groups-detail',
          params: { id: this.chosenId! },
        });
        break;
      case 'create':
        this.chosenId = null;
        this.$router.push({ name: 'groups-create' });
        break;
      default:
        this.chosenId = null;
        this.$router.push({ name: 'groups' });
        break;
    }
  }

  isTherapyClient(type: string): boolean {
    return type === Client.therapy;
  }

  getLicenseDate(licenseUntil: string): string {
    return dayjs(licenseUntil).format('DD.MM.YYYY');
  }

  getClientTypeName(clientType: string): string {
    switch (clientType) {
      case Client.school:
        return 'Schule';
      case Client.therapy:
        return 'Therapie';
      case Client.parent:
        return 'Eltern';
      default:
        return 'Superadmin';
    }
  }

  getTypeColor(type: string) {
    switch (type) {
      case Client.school:
        return 'blue darken-4';
      case Client.therapy:
        return 'green darken-2';
      case Client.parent:
        return 'red darken-2';
      default:
        return 'grey';
    }
  }

  async fetchAllClients() {
    await this.clientModule.fetchAllClients();
  }

  async mounted() {
    await this.fetchAllClients();
  }

  goToDetailPage(item: GetClientDto) {
    this.chosenId = item.schoolId.toString();
    this.setRoute('detail');
  }

  openCreate() {
    this.showCreateClient = true;
  }

  closeCreate() {
    this.showCreateClient = false;
  }
}
