
import { Client, GetClientDto, UpdateClientDto } from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import dayjs from 'dayjs';
import * as allCountries from 'i18n-iso-countries';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import {
  email,
  maxLength,
  minValue,
  required,
  requiredIf,
} from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import AdminSnackbar from '../admin/base/AdminSnackbar.vue';
import ClientDeleteDialog from './ClientDeleteDialog.vue';
import ManageClasses from './school-classes/manage-classes.vue';
// eslint-disable-next-line @typescript-eslint/no-var-requires
allCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const hasNoLeadingZero = (value: any) => {
  if (!value) return true;
  return value[0] !== '0';
};

export const isValidDate = (value: any) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  // https://stackoverflow.com/questions/51224/regular-expression-to-match-valid-dates comment: Harendra Chauhan
  return /(((0[1-9]|[12][0-9]|3[01])([.])(0[13578]|10|12)([.])(\d{4}))|(([0][1-9]|[12][0-9]|30)([.])(0[469]|11)([.])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([.])(02)([.])(\d{4}))|((29)(\/)(02)([.])([02468][048]00))|((29)([.])(02)([.])([13579][26]00))|((29)([.])(02)([.])([0-9][0-9][0][48]))|((29)([.])(02)([.])([0-9][0-9][2468][048]))|((29)([.])(02)([.])([0-9][0-9][13579][26])))/.test(
    value,
  );
};

@Component({
  components: { AdminSnackbar, ClientDeleteDialog, ManageClasses },
  mixins: [validationMixin],
  validations: {
    clientType: {
      required,
    },
    schoolId: {
      required,
      hasNoLeadingZero,
    },
    schoolName: {
      required,
    },
    slug: {
      required,
    },
    postcode: {
      required,
      minValue: minValue(1000),
    },
    street: {
      required,
    },
    city: {
      required,
    },
    country: {
      required,
    },
    adminFirstname: {
      required,
    },
    adminLastname: {
      required,
    },
    adminEmail: {
      required,
      email,
    },

    licenseUntil: {
      required,
      isValidDate,
      maxLength: maxLength(10),
    },
    classes: {
      required,
    },
    validTests: {
      required: requiredIf(function (this: any) {
        return this.clientType === Client.therapy;
      }),
    },
  },
})
export default class AddEditGroup extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  clientModule: ClientModule = getModule(ClientModule, this.$store);

  defaultClasses = [
    '2.Schulstufe',
    '3.Schulstufe',
    '4.Schulstufe',
    '5.Schulstufe',
    '6.Schulstufe',
  ];

  types = [
    { name: 'Schule', type: Client.school },
    { name: 'Therapie', type: Client.therapy },
    { name: 'Eltern', type: Client.parent },
  ];

  homeCountry = 'Österreich';
  firstCountries = ['Deutschland', 'Schweiz'];
  client: GetClientDto | null = null;

  countrySort = (a: string, b: string) => {
    if (a === this.homeCountry) {
      return -1;
    } else if (b === this.homeCountry) {
      return 1;
    }
    if (this.firstCountries.includes(a) && this.firstCountries.includes(b)) {
      return a.localeCompare(b);
    } else if (this.firstCountries.includes(a)) {
      return -1;
    } else if (this.firstCountries.includes(b)) {
      return 1;
    } else {
      return a.localeCompare(b);
    }
  };

  countries = Object.values(allCountries.getNames('de')).sort(this.countrySort);
  search = '';

  id: string | null = null;

  loading = false;
  loadingClient = false;
  loadingAvv = false;
  loadingManual = false;
  loadingTTManual = false;
  loadingStudents = false;
  showDeleteDialog = false;
  showPassword = false;
  showConfirmPassword = false;

  deleteType: 'client' | 'students' | null = null;

  clientType = Client.school;
  schoolId: string | null = null;
  schoolName: string | null = null;
  street: string | null = null;
  postcode: number | null = null;
  city: string | null = null;
  country: string | null = null;
  adminFirstname: string | null = null;
  adminLastname: string | null = null;

  defaultAdminPassword: string | null = null;

  email: string | null = null;
  licenseUntil: string | null = null;
  classes: string | null = null;
  isMiddleschool = false;
  isTutorialOnly = false;
  validTests: number | null = null;
  slug: string | null = null;

  @Watch('clientType')
  changedType(current: any) {
    if (!this.isEdit) {
      if (current !== Client.school) {
        this.classes = this.defaultClasses.join(',');
      } else {
        this.classes = null;
      }
    }
  }

  get adminEmail(): string | null {
    return this.email?.trim() ?? null;
  }

  set adminEmail(value: string | null) {
    this.email = value;
  }

  get isTherapyClient(): boolean {
    return this.clientType === Client.therapy;
  }

  get isSchoolClient(): boolean {
    return this.clientType === Client.school;
  }

  get isEdit(): boolean {
    return this.$route.params.id !== undefined;
  }

  goToGroupTable() {
    this.$router.push({ name: 'groups' });
  }

  getNumOfStudents() {
    return this.client?.numOfStudents ?? 0;
  }

  openDeleteDialog(type: 'client' | 'students') {
    this.deleteType = type;
    this.showDeleteDialog = true;
  }

  closeDeleteDialog() {
    this.deleteType = null;
    this.showDeleteDialog = false;
  }

  async closeAndRefetch() {
    await this.fetchCurrentClient();
    this.closeDeleteDialog();
  }

  async fetchCurrentClient() {
    try {
      this.id = this.$route.params.id;
      this.loadingClient = true;
      await this.clientModule.fetchClient(this.id);
      this.client = this.clientModule.currentClient;
      this.clientType = this.client.clientType;
      this.schoolId = this.client.schoolId.toString();
      this.schoolName = this.client.schoolName ? this.client.schoolName : null;
      this.slug = this.client.slug ? this.client.slug : null;
      this.street = this.client.street ? this.client.street : null;
      this.postcode = this.client.postcode ? this.client.postcode : null;
      this.city = this.client.city ? this.client.city : null;
      this.country = this.client.country
        ? allCountries.getName(this.client.country!, 'de')!
        : null;
      this.licenseUntil = this.client.licenseUntil
        ? dayjs(this.client.licenseUntil).format('DD.MM.YYYY')
        : null;
      this.classes = this.client.classes
        ? this.client.classes?.join(',')
        : null;
      this.validTests =
        this.client.validTests || this.client.validTests === 0
          ? this.client.validTests
          : null;
      this.adminFirstname = this.client.adminFirstname
        ? this.client.adminFirstname
        : null;
      this.adminLastname = this.client.adminLastname
        ? this.client.adminLastname
        : null;
      this.adminEmail = this.client.adminEmail ? this.client.adminEmail : null;
    } catch (error) {
      console.log('could not fetch client');
      console.log(error);
    } finally {
      this.loadingClient = false;
    }
  }

  async mounted() {
    await this.fetchCurrentClient();
  }

  async editClient() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        const client: UpdateClientDto = {
          clientType: this.clientType,
          schoolId: +this.schoolId!,
          schoolName: this.schoolName!,
          street: this.street!,
          postcode: +this.postcode!,
          city: this.city!,
          country: allCountries.getAlpha2Code(this.country!, 'de')!,
          adminFirstname: this.adminFirstname!,
          adminLastname: this.adminLastname!,
          adminEmail: this.adminEmail!,
          licenseUntil: this.licenseUntil!.split('.').reverse().join('-'),
          // TODO remove from here
          classes: this.classes!.split(',').map((c) => c.trim()),
          validTests: this.validTests ? +this.validTests : undefined,
          slug: this.slug!,
          url: `${window.location.origin}/#/${this.slug!}`,
          isTutorialOnly: false,
        };
        await this.clientModule.updateClient({
          groupId: this.client!.id,
          client: client,
        });
        this.snackbarModule.showMessage({
          message: this.$i18n.t('client.snackbar.edit.success').toString(),
          isSuccess: true,
        });
        await this.fetchCurrentClient();
        await this.clientModule.fetchAllClients();
      } catch (e: any) {
        const error = e.response?.data?.message;
        if (error.includes('Group with same schoolId already exists')) {
          this.snackbarModule.showMessage({
            message: this.$i18n
              .t('client.snackbar.add.conflictError')
              .toString(),
            isSuccess: false,
          });
        } else if (error.includes('Group with same slug already exists')) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('client.snackbar.add.slugError').toString(),
            isSuccess: false,
          });
        } else if (error.includes('Admin Email already registered')) {
          this.snackbarModule.showMessage({
            message: this.$i18n
              .t('client.snackbar.add.adminEmailError')
              .toString(),
            isSuccess: false,
          });
        } else if (error.includes('could not create admin for')) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('client.snackbar.add.adminError').toString(),
            isSuccess: false,
          });
          await this.clientModule.fetchAllClients();
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('client.snackbar.edit.error').toString(),
            isSuccess: false,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }

  async getAvvPdf() {
    try {
      this.loadingAvv = true;
      await this.clientModule.downloadAvv(this.client!);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.pdf.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.pdf.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingAvv = false;
    }
  }

  async getAdminManual() {
    try {
      this.loadingManual = true;
      if (!this.defaultAdminPassword) {
        throw new Error('Password must not be null');
      }
      await this.clientModule.downloadAdminManual({
        client: this.client!,
        code: this.defaultAdminPassword,
      });
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.pdf.success').toString(),
        isSuccess: true,
      });
      this.defaultAdminPassword = null;
    } catch (error: any) {
      console.log(error);
      if (error.message === 'Password must not be null') {
        this.snackbarModule.showMessage({
          message: this.$i18n.t('client.snackbar.pdf.password').toString(),
          isSuccess: false,
        });
      } else {
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.pdf.error').toString(),
          isSuccess: false,
        });
      }
    } finally {
      this.loadingManual = false;
    }
  }

  async getTeacherTherapistManual() {
    try {
      this.loadingTTManual = true;

      await this.clientModule.downloadTeacherTherapistManual(this.client!);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.pdf.success').toString(),
        isSuccess: true,
      });
      this.defaultAdminPassword = null;
    } catch (error: any) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.pdf.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadingTTManual = false;
    }
  }

  get schoolNameErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.name').toString();
    if (!this.$v.schoolName.$dirty) return errors;
    !this.$v.schoolName.required && errors.push(requiredError);
    return errors;
  }

  get schoolIdErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.id').toString();
    const zeroError = this.$t('client.form.zero').toString();
    if (!this.$v.schoolId.$dirty) return errors;
    !this.$v.schoolId.hasNoLeadingZero && errors.push(zeroError);
    !this.$v.schoolId.required && errors.push(requiredError);
    return errors;
  }

  get streetErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.street').toString();
    if (!this.$v.street.$dirty) return errors;
    !this.$v.street.required && errors.push(requiredError);
    return errors;
  }

  get postcodeErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.postcode').toString();
    const minValueError = this.$t('client.form.minValue').toString();
    if (!this.$v.postcode.$dirty) return errors;
    !this.$v.postcode.minValue && errors.push(minValueError);
    !this.$v.postcode.required && errors.push(requiredError);
    return errors;
  }

  get cityErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.city').toString();
    if (!this.$v.city.$dirty) return errors;
    !this.$v.city.required && errors.push(requiredError);
    return errors;
  }

  get countryErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.country').toString();
    if (!this.$v.country.$dirty) return errors;
    !this.$v.country.required && errors.push(requiredError);
    return errors;
  }

  get adminFirstnameErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.adminFirstname').toString();
    if (!this.$v.adminFirstname.$dirty) return errors;
    !this.$v.adminFirstname.required && errors.push(requiredError);
    return errors;
  }

  get adminLastnameErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.adminLastname').toString();
    if (!this.$v.adminLastname.$dirty) return errors;
    !this.$v.adminLastname.required && errors.push(requiredError);
    return errors;
  }

  get adminEmailErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.adminEmail').toString();
    const emailError = this.$t('client.form.invalidEmail').toString();
    if (!this.$v.adminEmail.$dirty) return errors;
    !this.$v.adminEmail.email && errors.push(emailError);
    !this.$v.adminEmail.required && errors.push(requiredError);
    return errors;
  }

  get licenseUntilErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.licenseUntil').toString();
    const validDateError = this.$t('client.form.invalidDate').toString();
    const maxLengthError = this.$t('client.form.maxLength').toString();
    if (!this.$v.licenseUntil.$dirty) return errors;
    !this.$v.licenseUntil.maxLength && errors.push(maxLengthError);
    !this.$v.licenseUntil.isValidDate && errors.push(validDateError);
    !this.$v.licenseUntil.required && errors.push(requiredError);
    return errors;
  }

  get classesErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.classes').toString();
    if (!this.$v.classes.$dirty) return errors;
    !this.$v.classes.required && errors.push(requiredError);
    return errors;
  }

  get validTestsErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.validTests').toString();
    if (!this.$v.validTests.$dirty) return errors;
    !this.$v.validTests.required && errors.push(requiredError);
    return errors;
  }

  get adminPasswordErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.adminPassword').toString();
    const minLength = this.$t('client.form.minLength').toString();
    if (!this.$v.adminPassword.$dirty) return errors;
    !this.$v.adminPassword.required && errors.push(requiredError);
    !this.$v.adminPassword.minLength && errors.push(minLength);
    return errors;
  }

  get confirmPasswordErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.confirmPassword').toString();
    const noMatch = this.$t('client.form.passwordNoMatch').toString();
    if (!this.$v.confirmPassword.$dirty) return errors;
    !this.$v.confirmPassword.sameAsPassword && errors.push(noMatch);
    !this.$v.confirmPassword.required && errors.push(requiredError);
    return errors;
  }

  get slugErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('client.form.slug').toString();
    if (!this.$v.slug.$dirty) return errors;
    !this.$v.slug.required && errors.push(requiredError);
    return errors;
  }
}
