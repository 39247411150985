var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{staticClass:"groupsTable"},[_c('v-col',{staticClass:"d-flex pa-0 pt-4 justify-center white",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t('client.management')))])]),_c('v-col',{staticClass:"page-tabs d-flex pa-0",attrs:{"cols":"12"}},[_c('v-tabs',{staticClass:"ma-0",attrs:{"centered":""}},[_c('v-tab',{on:{"click":function($event){return _vm.setRoute('all')}}},[_vm._v(_vm._s(_vm.$t('client.all')))]),_c('v-tab',{on:{"click":function($event){return _vm.setRoute('create')}}},[_vm._v(_vm._s(_vm.$t('client.create')))])],1)],1),(_vm.isTablePage)?[_c('v-col',{staticClass:"search-field d-flex justify-space-between pa-0",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"pa-0 mb-7",attrs:{"cols":"12","sm":"12","lg":"4"}},[_c('ImportToMongoButton')],1),_c('v-col',{staticClass:"pa-0 mb-7",attrs:{"cols":"12","sm":"12","lg":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clients,"sort-by":"grade","search":_vm.search,"id":"clients-table","locale":'de-GE',"footer-props":{
        itemsPerPageText: 'Zeilen pro Seite',
        pageText: '{0}-{1} von {2}',
        itemsPerPageAllText: 'Alle',
        itemsPerPageOptions: [50, 100],
      }},on:{"click:row":_vm.goToDetailPage},scopedSlots:_vm._u([{key:`item.licenseUntil`,fn:function({ item }){return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.getLicenseDate(item.licenseUntil)))])]}},{key:`item.clientType`,fn:function({ item }){return [(item.clientType)?_c('v-chip',{attrs:{"dark":"","color":_vm.getTypeColor(item.clientType)}},[_vm._v(_vm._s(_vm.getClientTypeName(item.clientType)))]):_c('MissingProperty')]}},{key:`item.validTests`,fn:function({ item }){return [(
            _vm.hasValidTestsProperty(item) && _vm.isTherapyClient(item.clientType)
          )?_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(item.validTests)+" ")]):_vm._e(),(
            !_vm.hasValidTestsProperty(item) && _vm.isTherapyClient(item.clientType)
          )?_c('MissingProperty'):_vm._e()]}},{key:`item.adminFirstname`,fn:function({ item }){return [(item.adminFirstname)?_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(item.adminFirstname)+" ")]):_c('MissingProperty')]}},{key:`item.adminLastname`,fn:function({ item }){return [(item.adminLastname)?_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(item.adminLastname)+" ")]):_c('MissingProperty')]}},{key:`item.adminEmail`,fn:function({ item }){return [(item.adminEmail)?_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.adminEmail))]):_c('MissingProperty')]}},{key:`item.street`,fn:function({ item }){return [(item.street)?_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.street))]):_c('MissingProperty')]}},{key:`item.postcode`,fn:function({ item }){return [(item.postcode)?_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.postcode))]):_c('MissingProperty')]}},{key:`item.city`,fn:function({ item }){return [(item.city)?_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.city))]):_c('MissingProperty')]}},{key:`item.country`,fn:function({ item }){return [(item.country)?_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.country))]):_c('MissingProperty')]}},{key:`item.slug`,fn:function({ item }){return [(item.slug)?_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.slug))]):_c('MissingProperty')]}},{key:`item.url`,fn:function({ item }){return [(item.url)?_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.url))]):_c('MissingProperty')]}}],null,true)})]:_vm._e(),(_vm.isCreatePage)?_c('AddGroup'):_vm._e(),(_vm.isDetailPage)?_c('EditGroup'):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }